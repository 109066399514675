import CommonLayout from '../layouts/CommonLayout.vue';
import AdminLayout from '../layouts/AdminLayout.vue';
import LoginView from '../views/login/index.vue';
import ErrorView from '../views/error/index.vue';
import { useBaseMenuStore, useBaseRouteStore, useBaseUserStore } from '../store/modules';
import { ElMessage } from 'element-plus';
import { JuouComponentsMap, RouteComponent, Router, useRouter } from './router.define';
import { useBaseOperateStore } from '@/store/modules/baseOperateStore';

const allComponents: JuouComponentsMap = {};

const requireCnt = require.context('../views/base', true, /index\.vue$/u);
requireCnt.keys().forEach((key) => {
  console.log(key);
  const dirname = key.split('/').at(-2);
  console.log(dirname);
  allComponents[dirname!] = () => requireCnt(key).default || requireCnt(key);
});

// 添加基础路由
const addBaseRoute = (router: Router, adminLayoutCnt?: RouteComponent) => {
  // 添加静态路由
  router.addRoute({
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: '登录',
    },
  });
  router.addRoute({
    path: '/admin',
    name: 'admin_layout',
    component: adminLayoutCnt || AdminLayout,
    children: [],
  });
  router.addRoute({
    path: '/',
    name: 'common_layout',
    component: CommonLayout,
    children: [],
  });
  router.addRoute({
    path: '/:pathMatch(.*)*',
    meta: {
      title: '404',
    },
    component: ErrorView,
  });
  // todo: 错误页面
};

const routeTypeToLayout = (routeType: string) => {
  switch (routeType) {
    case 'admin_page':
      return 'admin_layout';
    case 'common_page':
      return 'common_layout';
    default:
      return 'common_layout';
  }
};

const addRouteDfs = (routes: any[], router: Router, parentName?: string) => {
  routes.forEach((item) => {
    const routeObj = {
      path: item.path,
      name: item.componentName,
      meta: {
        title: item.title,
      },
      redirect: item.redirect,
      component: allComponents[item.componentName]?.(),
      children: [],
    };

    const rootName = routeTypeToLayout(item.type);

    // 如果是嵌套
    if (item.isNest) {
      router.addRoute(parentName || rootName, routeObj);
    } else {
      router.addRoute(rootName, routeObj);
    }
    // 添加子路由
    if (item.children?.length) {
      addRouteDfs(item.children, router, item.componentName);
    }
  });
};

// 添加非授权路由
const addNoPermissionRoutes = async(router: Router) => {
  const baseRouteStore = useBaseRouteStore();

  await baseRouteStore.getNoPermissionRoutes();
  if (baseRouteStore.noPermissionRoutes?.length) {
    addRouteDfs(baseRouteStore.noPermissionRoutes, router);
  }
};

// 添加授权路由
const addPermissionRoutes = async(router: Router) => {
  const baseRouteStore = useBaseRouteStore();
  const baseUserStore = useBaseUserStore();

  await baseRouteStore.getPermissionRoutes();
  if (!baseRouteStore.permissionRoutes?.length) {
    ElMessage.error('当前用户无任何权限！');
    // 无授权路由,退出登录
    baseUserStore.logout();
    return;
  }

  addRouteDfs(baseRouteStore.permissionRoutes, router);
};


// eslint-disable-next-line max-lines-per-function
export const initRouter = (siteName: string, viewMap: JuouComponentsMap, router: Router, adminLayoutCnt?: RouteComponent) => {
  // 添加项目view的组件
  Object.assign(allComponents, viewMap);

  console.log(allComponents);

  // 拦截器：/开头的普通页面，直接访问，路由不存在或者未授权，直接显示404页面
  // /admin开头的管理页面，直接访问，路由不存在或者未授权，跳转登录页面
  // 访问/判断是否重写，如果重写直接进，如果没重写，跳转第一个子路由
  // 访问 /admin, 跳转第一个子路由，不能重写/admin
  // eslint-disable-next-line max-lines-per-function
  router.beforeEach(async(to, from, next) => {
    const baseUserStore = useBaseUserStore();
    const baseRouteStore = useBaseRouteStore();
    const baseMenuStore = useBaseMenuStore();
    const baseOperateStore = useBaseOperateStore();

    // 动态设置标题
    document.title = to.meta.title ? `${to.meta.title} - ${siteName}` : siteName;

    // 初始化，添加基础路由
    if (!router.getRoutes().find((item) => item.name === 'login')) {
      addBaseRoute(router, adminLayoutCnt);
      return next({ ...to, replace: true });
    }

    // 访问登陆页，有 Token 就在当前页面，不跳转登陆，没有 Token 重置路由到登陆页
    if (to.path.toLocaleLowerCase() === '/login') {
      if (baseUserStore.token) {
        return next(from.fullPath);
      }
      // 重置授权路由
      baseRouteStore.resetPermissionRoutes();
      // 重置菜单
      baseMenuStore.resetMenus();
      // 充值操作
      baseOperateStore.resetOperates();
      return next();
    }

    // 判断无须授权的路由是否存在，不存在则获取
    if (!baseRouteStore.noPermissionRoutes) {
      await addNoPermissionRoutes(router);
      return next({ ...to, replace: true });
    }

    // 判断授权的路由是否存在，不存在且用户已经登陆则获取
    if (!baseRouteStore.permissionRoutes && baseUserStore.token) {
      await addPermissionRoutes(router);
      return next({ ...to, replace: true });
    }

    if (!baseOperateStore.operates) {
      await baseOperateStore.getOperates();
    }

    // 访问首页
    if (to.path === '/') {
      // 没有普通页面，则没有重写，也没有其他页面，跳转后台首页
      if (!baseRouteStore.flatCommonLayoutRoutes.length) {
        return next({
          path: '/admin',
          replace: true,
        });
      }

      // 没有重写首页，跳转第一个子页面
      if (!baseRouteStore.flatCommonLayoutRoutes.find((item: any) => item.path === '/')) {
        return next({
          path: baseRouteStore.flatCommonLayoutRoutes[0].path,
          replace: true,
        });
      }
    }

    // 访问的是后台页面
    if (to.path.startsWith('/admin')) {
      // 判断token是否存在，如果不存在，直接跳转到登陆界面
      if (!baseUserStore.token) {
        return next({
          path: '/login',
          query: {
            back: to.path,
          },
        });
      }


      // 获取动态菜单
      if (!baseMenuStore.menus) {
        await baseMenuStore.getMenus();
      }

      // 访问后台首页，直接跳转第一个子路由
      if (to.path === '/admin') {
        // 没有后台页面，退出登录，跳转登录
        if (!baseRouteStore.flatAdminLayoutRoutes.length) {
          baseUserStore.logout();
          return next({
            path: '/login',
            query: {
              back: to.path,
            },
          });
        }

        // 有子路由，跳转第一个
        return next({
          path: baseRouteStore.flatAdminLayoutRoutes[0].path,
          replace: true,
        });
      }
    }

    return next();
  });
};
