import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';

export class BaseOperateVo {

  public id?: number;

  public key!: string;

  public name!: string;

  public routePath!: string;

}

export interface BaseOperateState {
  operates?: BaseOperateVo[];
}


export const useBaseOperateStore = defineStore({
  id: 'juoumanagebase-operate',
  state: (): BaseOperateState => ({
    operates: undefined,
  }),
  actions: {
    async getOperates() {
      try {
        const res = await useApi().baseOperateApi.getOperatesBaseOperate();
        console.log(res.data.data);
        if (res.data.data) {
          this.operates = res.data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.operates = this.operates || [];
      }
    },
    resetOperates() {
      this.$reset();
    },
  },
});
