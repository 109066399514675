import { defineStore } from 'pinia';
import { piniaPersistConfig } from '../config';

export interface BaseConfigState {
  siteName?: string;
  logo?: string;
  description?: string;
  topNav: boolean;
}

export const useBaseConfigStore = defineStore({
  id: 'juoumanagebase-config',
  state: (): BaseConfigState => ({
    siteName: undefined,
    logo: undefined,
    description: undefined,
    topNav: false,
  }),
  getters: {

  },
  actions: {
    setSiteName(_v: string) {
      this.siteName = _v;
    },
    setLogo(_v: string) {
      this.logo = _v;
    },
    setDescription(_v: string) {
      this.description = _v;
    },
    setTopNav(_v: boolean) {
      this.topNav = _v;
    },
  },
  persist: piniaPersistConfig('juoumanagebase-config'),
});
