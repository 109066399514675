import { useApi } from '@/hooks/useApi';
import { defineStore } from 'pinia';
import { piniaPersistConfig } from '../config';

export interface BaseUserState {
  token: string;
  username: string;
  phone: number;
  avatar: string;
  nickname: string;
  onLogout?: () => void | Promise<void>;
}

export const useBaseUserStore = defineStore({
  id: 'juoumanagebase-user',
  state: (): BaseUserState => ({
    token: '',
    username: '',
    phone: 0,
    avatar: '',
    nickname: '',
  }),
  getters: {

  },
  actions: {
    setOnLogout(_fun: () => void | Promise<void>) {
      this.onLogout = _fun;
    },
    async login(usernameOrPhone: string, password: string) {
      try {
        const res = await useApi().baseUserApi.loginBaseUser(usernameOrPhone, password);
        if (res.data.data) {
          this.token = res.data.data.accessToken || '';
          this.username = res.data.data.username || '';
          this.phone = res.data.data.phone || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    logout() {
      this.$reset();
      if (this.onLogout) {
        Promise.resolve(this.onLogout());
      }
    },
  },
  persist: piniaPersistConfig('juoumanagebase-user'),
});
